import React, { useState, lazy } from 'react';
import { Layout, Menu, Drawer, Button } from 'antd';
import { MenuOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../assets/updateLogo.png'
import OurProducts from "../../dropdown/ourProducts/OurProducts";
import OurProviders from "../../dropdown/ourProviders/OurProviders"; // Adjust the import based on your file structure
import './header.css'

const { Header: Navbar } = Layout;

const Header = () => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const navigate = useNavigate();

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const onClose = () => {
    setDrawerVisible(false);
    setActiveDropdown(null);
  };
  const toggleDropDown = (dropdown) => {
    if (activeDropdown === dropdown) {
      setActiveDropdown(null);
    } else {
      setActiveDropdown(dropdown);
    }
  };

  return (
    <Layout className='header-container'>
      <Navbar className="header">
        <div onClick={() => navigate('/')} style={{ cursor: 'pointer' }} className="logo">
          <img src={logo} alt="logo" />
        </div>

        <div className="menu-container" >
          <div className="hoverable-product-link">
            <Link className="product-link" style={{ color: 'var(--bg-primary)', fontWeight: '700' }}> Our Products <DownOutlined style={{ fontSize: '10px' }} /></Link>
            <div className="hover-product-content">
              <OurProducts />
            </div>
          </div>
          <div className="hoverable-provider-link">
            <Link className="provider-link" style={{ color: '#706FE5', fontWeight: "500" }}>Our Providers <DownOutlined style={{ fontSize: '10px' }} /></Link>
            <div className="hover-provider-content">
              <OurProviders />
            </div>
          </div>
          <Link to={'/#pricing'} style={{ color: '#706FE5', fontWeight: "500" }}> Pricing </Link>
        </div>
        <div className="buttons">
          <Button onClick={() => navigate('/providers')} style={{ marginRight: '10px', borderColor: "var(--bg-primary)", color: 'var(--bg-primary)', font: 'Clash Display', fontWeight: "500" }}>LEARN MORE</Button>
          <Button onClick={() => window.location = process.env.REACT_APP_PORTAL_URL + '/auth/login'} style={{ backgroundColor: "var(--bg-primary)", color: "#fff", font: 'Clash Display', fontWeight: "500" }} type="default">PROVIDERS LOGIN</Button>
        </div>

        <Button className="mobile-menu-button" type="text" icon={<MenuOutlined />} onClick={showDrawer} />

        <Drawer
          title="Menu"
          placement="right"
          closable={true}
          onClose={onClose}
          visible={drawerVisible}
        >
          <Menu style={{ position: "relative" }} mode="vertical">
            <Menu.Item onClick={() => toggleDropDown('products')}><Link className="product-link" style={{ color: 'var(--bg-primary)', fontWeight: '700' }}> Our Products {activeDropdown === 'products' ? <UpOutlined /> : <DownOutlined />}</Link></Menu.Item>
            {activeDropdown === 'products' && <OurProducts />}

            <Menu.Item onClick={() => toggleDropDown('providers')}><Link className="provider-link" style={{ color: '#706FE5', fontWeight: "500" }}>Our Providers {activeDropdown === 'products' ? <UpOutlined /> : <DownOutlined />}</Link></Menu.Item>
            {activeDropdown === 'providers' && <OurProviders />}

            <Menu.Item onClick={onClose}><Link to={'/#pricing'} style={{ color: '#706FE5', fontWeight: "500" }}> Pricing </Link></Menu.Item>

          </Menu>
          <Button onClick={() => { navigate('/providers'); onClose() }} style={{ marginTop: '10px', width: '100%', borderColor: "var(--bg-primary)", color: 'var(--bg-primary)', font: 'Clash Display', fontWeight: "500" }}>LEARN MORE</Button>
          <Button onClick={() => { window.location = process.env.REACT_APP_PORTAL_URL + '/auth/login'; onClose() }} style={{ marginTop: '10px', width: '100%', backgroundColor: "var(--bg-primary)", color: "#fff", font: 'Clash Display', fontWeight: "500" }} type="default">PROVIDERS LOGIN</Button>
        </Drawer>
      </Navbar>
    </Layout>
  );
};

export default Header;
