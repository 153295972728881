import React, { useState } from 'react'
import { Row, Col, Form, Input, Button, message } from 'antd';
import FormImage from '../../assets/Landingform.png'
import FormGroup from '../../assets/Group.png'
import './LandingForm.css';

const LandingForm = () => {
    const [state, setState] = useState({
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        practiceName: '',
    })
    const [validation, setValidation] = useState(false)
    const [loading, setLoading] = useState(false)
    const { firstName, lastName, phone, email, practiceName, } = state
    const onChange = (name, value) => {
        setState({ ...state, [name]: value })
    }

    const onSubmit = async () => {
        let emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
        if (!firstName || !lastName || !phone || !email || !practiceName) {
            setValidation(true)
            message.error("All fields are required")
        } else if (!emailRegex.test(email)) {
            message.error("Invalid email")
        } else {
            setLoading(true)
            try {
                const response = await fetch(process.env.REACT_APP_PAYMENT_URL + '/send-email-v2', {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                      ...state,
                      key: 'providerDemoRequestNew',
                      subject: 'Bluu Health Provider Demo',
                    }),
                  });
            
            } catch(e) {
                console.log("error", e)
            }
            setLoading(false)
            message.success("Thank you for reaching out! Someone from Bluu team will be contacting you shortly.")
            //After api call reset state
            setValidation(false)
            setState({
                firstName: '',
                lastName: '',
                phone: '',
                email: '',
                practiceName: '',
            })
        }
    }

    return (
        <>
            <div className="two-column-section">
                <Row gutter={[2, 16]} justify="center">
                    {/* Left Column */}
                    <Col xs={{ span: 24, order: 2 }} md={{ span: 12, order: 1 }}>
                        <div id="learn-more" className="left-column">
                            <h1 style={{ fontWeight: 700, color: "#1E1F4B", fontFamily: "Poppins" }}>Learn More Today    </h1>
                            <p style={{ color: "#2E3E5C", fontFamily: "Poppins" }}>Providers choose Bluu to focus on their clients and grow their business. Bluu consolidates everything you need to manage your workflow into one easy-to-use platform</p>
                            <Form style={{ maxWidth: "100%" }} layout="vertical">
                                <Form.Item >
                                    <Input value={firstName} status={validation && !firstName && 'error'} onChange={({ target }) => onChange('firstName', target.value)} style={{ height: "50px" }} placeholder="First Name" />
                                </Form.Item>
                                <Form.Item >
                                    <Input value={lastName} status={validation && !lastName && 'error'} onChange={({ target }) => onChange('lastName', target.value)} style={{ height: "50px" }} placeholder="Last Name" />
                                </Form.Item>
                                <Form.Item >
                                    <Input value={phone} status={validation && !phone && 'error'} onChange={({ target }) => onChange('phone', target.value)} style={{ height: "50px" }} placeholder="Phone" />
                                </Form.Item>
                                <Form.Item >
                                    <Input value={email} status={validation && !email && 'error'} onChange={({ target }) => onChange('email', target.value)} style={{ height: "50px" }} placeholder="Email" />
                                </Form.Item>
                                <Form.Item >
                                    <Input value={practiceName} status={validation && !practiceName && 'error'} onChange={({ target }) => onChange('practiceName', target.value)} style={{ height: "50px" }} placeholder="Practice Name" />
                                </Form.Item>
                                <Form.Item>
                                    <Button disabled={loading} onClick={onSubmit} style={{
                                        backgroundColor: '#706FE5', color: "#fff", maxWidth: "100%", padding: '18px 24px 18px 24px'
                                    }}>Get a Demo</Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </Col>

                    {/* Right Column */}
                    <Col xs={{ span: 24, order: 1 }} md={{ span: 12, order: 2 }}>
                        <div className="right-column">
                            <div className='right-column-absolutebox' >
                                <img src={FormGroup} alt="Image" />
                            </div>
                            <img src={FormImage} alt="Image" />
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default LandingForm